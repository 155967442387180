<template>
  <div>
    <highcharts :options="chartOptions"></highcharts>
  </div>
</template>

<script>
import { Chart } from "highcharts-vue";
import axios from "axios";

export default {
  name: "ComGraficaVentasClientes",
  components: {
    highcharts: Chart,
  },

  data() {
    return {
      chartOptions: {
        xAxis: {
          categories: [],
          title: {
            text: "Clientes",
          },
        },

        yAxis: {
          title: {
            text: "Ventas",
          },
        },

        exporting: {
          enabled: true,
        },
        title: {
          text: "",
          align: "center",
        },
        subtitle: {
          text: "VENTAS MENSUALES",
          align: "center",
        },

        series: [
          {
            type: "column",
            data: [],
            marker: {
              lineWidth: 3,
              fillColor: "white",
            },
            color: {
              linearGradient: { x1: 0, x2: 0, y1: 0, y2: 1 },
              stops: [
                [0, "#201747"],
                [1, "#45589c"],
              ],
            },
          },
        ],

        credits: {
          enabled: false,
        },
      },
      respuesta: [],
    };
  },

  methods: {
    async getGrafica() {
      let me = this;
      await axios
        .get("/api/com/dashboardCom/clientesVentas", {
          params: this.$parent.filters,
        })
        .then(async (response) => {
          me.respuesta = response.data;
          await me.graficaTurnosFinalizados(me.respuesta);
        });
    },

    async graficaTurnosFinalizados(resp) {
      let venta = [];
      let cliente = [];
      for (let i = 0; i < this.respuesta.length; i++) {
        if (
          this.respuesta[i].nCliente != 0 &&
          this.respuesta[i].nCliente != null
        ) {
          cliente.push(this.respuesta[i].nCliente);
          venta.push(parseFloat(this.respuesta[i].total));
        }
      }
      this.chartOptions.xAxis.categories = cliente;
      this.chartOptions.series[0].data = venta;
      this.chartOptions.series[0].name = "Ventas Mensuales";
    },
  },
};
</script>
